// app/javascript/controllers/copy_controller.js

import { Controller } from "stimulus";

export default class extends Controller {
  copy(event) {
    event.preventDefault();

    const el = document.createElement('textarea');
    const contentContainer = document.getElementById('prompt-content');

    // Seulement le contenu interne
    el.value = contentContainer.firstElementChild.textContent;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    alert("Texte copié avec succès !");
  }
}
