import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["star"];

  hover(event) {
    let hoveredStar = event.currentTarget;
    this.fillStarsUpTo(hoveredStar);
  }

  reset() {
    this.starTargets.forEach((star) => {
      star.querySelector('.empty-star-img').style.display = 'inline';
      star.querySelector('.filled-star-img').style.display = 'none';
    });

    let checkedStar = this.element.querySelector('.star-radio:checked');
    if (checkedStar) {
      this.fillStarsUpTo(document.querySelector(`label[for="${checkedStar.id}"]`));
    }
  }

  select(event) {
    let selectedStar = event.currentTarget;
    let correspondingRadio = document.getElementById(selectedStar.getAttribute("for"));
    correspondingRadio.checked = true;
  }

  fillStarsUpTo(star) {
    let filled = false;
    this.starTargets.forEach((s) => {
      s.querySelector('.empty-star-img').style.display = filled ? 'inline' : 'none';
      s.querySelector('.filled-star-img').style.display = filled ? 'none' : 'inline';
      if (s === star) filled = true;
    });
  }
}
