import { Controller } from "stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  items = [];

  connect() {
    new TomSelect("#search-main-task", {
      maxItems: 2,
      valueField: 'slug',
      labelField: 'name',
      searchField: 'name',
      load: (query, callback) => {
        if (query.length < 3) return callback();
        fetch(`/categories/suggest?term=${encodeURIComponent(query)}`)
          .then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
              }
              return response.json();
            })
          .then(data => {
            this.items = data;
            callback(data);
          })
          .catch(err => callback());
      },
      render: {
        option: function (data, escape) {
          return `<div>
                   <i class="${data.icon}" style="margin-right: 5px;"></i>
                   <span class=""> ${escape(data.name)}</span>
                </div>`;
        }
      },
      onChange: (value) => {
        const selectedItem = this.items.find(item => item.slug === value);
        if (selectedItem) {
          let urlPath = selectedItem.item_type === 'category' ? '/c' : '/ai';
          window.location.href = `${urlPath}/${selectedItem.slug}`;
        }
      }
    });
  }
}

