import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["outputType", "textOutput", "imageOutput"]

  connect() {
    this.toggleOutput()
  }

  toggleOutput() {
    if (this.outputTypeTarget.value === "image") {
      this.textOutputTarget.style.display = "none"
      this.imageOutputTarget.style.display = "block"
    } else {
      this.textOutputTarget.style.display = "block"
      this.imageOutputTarget.style.display = "none"
    }
  }
}
